/* 
    FONTS
*/
@font-face {
    font-family: "Roboto";
    font-weight: 200;
    font-style: normal;
    font-display: swap;
    src: local("Roboto Thin"),
        url("./assets/fonts/roboto/Roboto-Thin.woff2") format("woff2"),
        url("./assets/fonts/roboto/Roboto-Thin.ttf") format("truetype");

}

@font-face {
    font-family: "Roboto";
    font-weight: 200;
    font-style: italic;
    font-display: swap;
    src: local("Roboto Thin Italic"),
        url("./assets/fonts/roboto/Roboto-ThinItalic.woff2") format("woff2"),
        url("./assets/fonts/roboto/Roboto-ThinItalic.ttf") format("truetype");

}

@font-face {
    font-family: "Roboto";
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: local("Roboto Light"),
        url("./assets/fonts/roboto/Roboto-Light.woff2") format("woff2"),
        url("./assets/fonts/roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    src: local("Roboto Light Italic"),
        url("./assets/fonts/roboto/Roboto-LightItalic.woff2") format("woff2"),
        url("./assets/fonts/roboto/Roboto-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: local("Roboto Regular"),
        url("./assets/fonts/roboto/Roboto-Regular.woff2") format("woff2"),
        url("./assets/fonts/roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: local("Roboto Medium"),
        url("./assets/fonts/roboto/Roboto-Medium.woff2") format("woff2"),
        url("./assets/fonts/roboto/Roboto-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-weight: 500;
    font-style: italic;
    font-display: swap;
    src: local("Roboto Medium Italic"),
        url("./assets/fonts/roboto/Roboto-MediumItalic.woff2") format("woff2"),
        url("./assets/fonts/roboto/Roboto-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: local("Roboto Bold"),
        url("./assets/fonts/roboto/Roboto-Bold.woff2") format("woff2"),
        url("./assets/fonts/roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    src: local("Roboto Bold Italic"),
        url("./assets/fonts/roboto/Roboto-BoldItalic.woff2") format("woff2"),
        url("./assets/fonts/roboto/Roboto-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    src: local("Roboto Black"),
        url("./assets/fonts/roboto/Roboto-Black.woff2") format("woff2"),
        url("./assets/fonts/roboto/Roboto-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-weight: 900;
    font-style: italic;
    font-display: swap;
    src: local("Roboto Black Italic"),
        url("./assets/fonts/roboto/Roboto-BlackItalic.woff2") format("woff2"),
        url("./assets/fonts/roboto/Roboto-BlackItalic.ttf") format("truetype");
}


